<template>
    <div class="popup_air_container">
        <div style="height: 100%; width: 100%; position: absolute;" @click="closeGuide"></div>
        <div class="popup_air_popup">
            <img :src="imgAirdropBanner" alt=""
                 style="height: 100%; width: 100%; position: absolute;"
            />
            <div class="popup_air_howtowin">
                <div class="popup_air_howtowin_fix" style="gap: 20px;">
                    <!--  Tittle 2 lines-->
                    <div class="popup_air_col" style="height: auto;">
                        <div class="popup_air_row" style="gap: 10px;">
                            <span class="popup_air_titleModal">
                              Introducing SpinFun
                            </span>
                            <img :src="imgSpinfun" alt=""
                                 style="height: 100%; width: auto;"
                            />
                        </div>
                        <span class="popup_air_titleModal">
                          The MemeCoin for Infinite Fun!
                        </span>
                    </div>

                    <!--  Description-->
                    <div class="popup_air_col" style="width:90%; height: auto;">
                        <span class="popup_air_description">
                              {{m_air_descr}}
                        </span>
                    </div>

                    <!--     Soon Btn       -->
                    <ButtonSvgOval
                            :btn-text="'Soon'"
                            :bg-width="150" :bg-height="40"
                            :font-size="20"
                            :svg-normal="imgSpinfunWhite"
                            :svg-width="30" :svg-height="30"
                            :backgroundVal="'linear-gradient(180deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,0) 58.54%),linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%) var(--x,0)/200%'"
                            :borderVal="'linear-gradient(102.76deg,'+'#CC181A'+' 3.6%,'+'#FDEE64'+' 53.89%,'+'#CC181A'+' 99.19%)'"
                            :callback="closeGuide" :callback-wait="true"
                            :click-scale="0.95"
                            :ss="1"
                    />

                    <div @click="closeGuide">
                        <img :src="imgX"
                             class="c-header-profile-close"
                             alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import imgAirdropBanner from "../../assets/images/airdrop/air_bg_desk.webp";
    import imgSpinfun from "../../assets/images/airdrop/spinfun.webp";
    import imgSpinfunWhite from "../../assets/svg-icons/chains/spinfun.svg";
    import imgX from "../../assets/svg-icons/icon_close.svg";
    import ButtonSvgOval from "../lucky-wheel/ButtonSvgOval";
    import {mapGetters} from "vuex";

    export default {
        name: 'AirdropPopup',
        props: {},
        ...mapGetters(["ss","realWidth","realHeight","xMult"]),
        components:{
            ButtonSvgOval,
        },
        data() {
            return {
                imgAirdropBanner: imgAirdropBanner,imgX:imgX,imgSpinfun:imgSpinfun,imgSpinfunWhite,
                m_air_descr:`We’re thrilled to roll out soon SpinFun, the MemeCoin that’s all about fun and zero pretence! SpinFun is here to add fun and excitement to our ever-evolving social casino ecosystem. Sure, it might be worth zilch on the exchange, but it will be priceless when it comes to spicing up your gameplay across our suite of games in development!

SpinFun will be your exclusive in-game pass to quirky features, bonus upgrades, and many more  utilities suggested by our community, lets build it together!
Don’t expect SpinFun to make you rich, but to keep the fun flowing!

A special play-to-airdrop mechanism is on reserve just for our community! Here’s a little insider tip: accumulate those streaks, level up, and get ready for the fun…`,
            }
        },
        methods: {

            closeGuide() {
                this.$store.state.airdropPopup = false;
            },

        }
    }
</script>

<style lang="scss" scoped>

    /*       ----------------------------------          */
    .popup_air_container {
        width: 100%;
        display: flex;
        overflow: auto;
        min-height: 100vh;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .popup_air_popup {
        width: 790px;
        height: 658px;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }

    .popup_air_howtowin {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .popup_air_howtowin_fix {
        position: relative;
        height: 538px;
        width: 670px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .popup_air_col {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .popup_air_row {
        position: relative;
        height: 35px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .popup_air_titleModal {
        font-size: 28px;
        line-height: 35px;
        font-style: normal;
        font-family: Rubik;
        font-weight: 700;
        font-stretch: normal;
    }

    .popup_air_description {
        font-size: 18px;
        line-height: 20px;
        font-style: normal;
        font-family: Rubik;
        font-weight: 400;
        font-stretch: normal;

        word-wrap: break-word;/**/
        white-space: pre-wrap;
    }

</style>
