<template>
  <div class="l-container l-home">
    <slots-blockchain />
    <div class="pageBody">
      <main class="l-home__main">
        <all-games class="l-section l-home__games" />
        <div class="l-home__jackpot-badge-mobile">
          <app-badge v-if="isMobile" class="l-section l-home__jackpot-badge" />
        </div>
        <app-banner />
      </main>
      <aside class="l-home__aside">
        <div class="l-home__jackpot-badge-wrap">
          <app-badge v-if="!isMobile" class="l-section l-home__jackpot-badge" />
        </div>
        <leaderboard-table
          class="l-section l-home__leaderboard"
        />
        <games-table
          v-if="$store.getters.getSelectedGamesType ==='slot'"
          class="l-section l-home__leaderboard"
        />
        <recent-bets-table class="l-section l-home__recent-bets" />
      </aside>
    </div>

    <!--guide popup--><transition name="slide-fade-dropdown">
      <div v-if="guidePopup || $store.getters.getIsGuideOpen">
        <div class="c-header-tutorial-wrap" @click="closeGuide()"></div>
        <div class="guide">
          <howtowin/>
        </div>
      </div>
    </transition>

    <!--chips guide popup--><transition name="slide-fade-dropdown">
      <div v-if="guidePopup || $store.getters.getIsChipsGuideOpen"
      >
        <div class="c-header-tutorial-wrap" @click="closeGuide()"></div>
        <div class="guide">
          <chips-guide/>
        </div>

      </div>
    </transition>

    <!--  Airdrop guide popup-->
    <transition name="slide-fade-dropdown">
    <div v-if="airdropPopup"
    >
      <div class="c-header-tutorial-wrap" @click="closeGuide()"></div>
      <div class="guide">
        <AirdropPopup/>
      </div>

    </div>
  </transition>

  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import LeaderboardTable from "../../components/leaderbord/leaderboard-table";
  import GamesTable from "../../components/leaderbord/games-table";
  import RecentBetsTable from "../../components/recent-bets/recent-bets-table";
  import SlotsBlockchain from "../../components/slots-blockchain/slots-blockchain";
  import AppBanner from "../../components/shared/app-banner";
  import AppBadge from "../../components/shared/app-badge";
  import AllGames from "../../components/all-games/all-games";
  import { allGames } from "../../constants/index";
  import Howtowin from "@/components/popups/howtowin-guide-popup.vue";
  import ChipsGuide from "@/components/popups/chips-guide-popup.vue";
  import AirdropPopup from "../../components/popups/AirdropPopup.vue";
export default {
  name: "HomePage",
  computed: {
    ...mapGetters(["airdropPopup",]),
  },
  components: {
    ChipsGuide,
    Howtowin,
    SlotsBlockchain,
    AllGames,
    AppBanner,
    AppBadge,
    GamesTable,
    LeaderboardTable,
    RecentBetsTable, AirdropPopup,
  },
  data() {
    return {
      allGames: allGames,
      isTest: false,
      guidePopup:false,
      guestGuidePopup:false,
      isMobile : window.innerWidth <= 900,
    };
  },
  mounted() {
    let url = window.location.hostname;
    if(url.includes("test.") || url.includes("localhost")) {
      this.isTest = true;
    }
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 900;
    });
  },
  methods:{
    closeGuide(){
      this.guidePopup=false;
      this.$store.commit("setGuideOpen", false)
    },
  }
};
</script>

<style lang="scss" scoped>
.l-home {
  display: flex;
  flex-direction: column;
  margin: auto;

  @include media-max(820px) {
    margin-top: 60px;
  }
}

.l-home__main {
  flex: 1 1 auto;
  min-width: 0;

  @include media-min(900px) {
    margin-right: 20px;
  }
  @include media-max(900px) {
    padding: 0 10px;
  }
}

.l-home__aside {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  @include media-min(900px) {
    min-width: 420px;
  }
}
.l-home__jackpot-badge{

  @include media-max(900px) {
    order: 2 !important;
    margin-top: 30px;
    row-gap: 5px;
  }
}
.l-home__jackpot-badge-mobile{
  z-index: 1;
  position: relative;
}
.l-home__jackpot-badge-wrap{
  position: relative;
}
.l-home__jackpot-badge-buttons-wrap{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.l-home__jackpot-badge-button{
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.pageBody{
  display: flex;
  flex-direction: row;
  @include media-max(900px) {
    flex-direction: column;
  }
}

.guide{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-max(690px) {
    height:100%;
    width: 100%;
  }
}
</style>
