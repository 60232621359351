<template>
  <section>
    <img
      :src="getIconPath('img_dice_pink.webp')"
      class="c-banner-floating-icons"
      alt="jackpot-banner"
      style="position: absolute;
      transform: translate(50px, 160px);
      z-index: 5;}"
    />
    <img
      :src="getIconPath('img_dice_blue.webp')"
      alt="jackpot-banner"
      class="c-banner-floating-icons"
      style="position: absolute;
      transform: translate(600px, -30px);
      z-index: 5;"
    />
    <div class="c-banner">
      <img
        :src="getIconPath('img_casino.webp')"
        alt="jackpot-banner"
        style="position: absolute;
width: 860px;
height: 236px;
left: 0;
top: 0;"
      />
      <img
        :src="getIconPath('img_rays.webp')"
        alt="jackpot-banner"
        style="
          position: absolute;
          width: 884px;
          height: 447px;
          left: -12px;
          top: -163px;
        "
      />
      <img
        :src="getIconPath('img_confetti.webp')"
        alt="jackpot-banner"
        style="position: absolute;
width: 860px;
height: 236px;
left: 0;
top: 0;"
      />
      <img
        :src="getIconPath('img_coin_1.webp')"
        alt="jackpot-banner"
        style="position: absolute;
height: 79px;
left: 218px;
top: 0;
"
      />
      <img :src="require('../../assets/svg-icons/svg_coin_3.svg')"
        style="position: absolute;
              width: 71px;
              height: 66px;
              left: 8px;
              bottom: 0;
              "
       alt=""/>
      <img :src="require('../../assets/svg-icons/svg_coin.svg')"
        style="position: absolute;
width: 81px;
height: 63px;
left: 735px;
top: 205px;
"
       alt=""/>

      <h2 class="c-banner__title">
        {{ $t("general.jackpot") }}
      </h2>
      <div class="c-banner__value">
        {{ $filters.formatNumber( this.$store.getters.getJackpot  ) }} CHIPS
      </div>
    </div>
  </section>
</template>

<script>


export default {
  name: "AppBanner",
  components: {  },
  data() {
    return {
      mobile: window.innerWidth <= 767,
    };
  },
  created() {
    // todo move in store
    addEventListener("resize", () => {
      this.mobile = innerWidth <= 767;
    });
  },
  methods: {
    getIconPath(iconName) {
      return iconName ? require(`../../assets/images/${iconName}`) : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.c-banner {

  height: 236px;
  position: relative;
  background: linear-gradient(91.95deg, #FCC031 -9.33%, #FF2E2F 92.67%);
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: $transition-medium;

  &:after {
    background: linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%);
    content: '';
    width: 100%;
    height: 100%;
    padding: 2px;
    top: 0;
    left: 0;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: 30px;
    position: absolute;

  }
}
.c-banner-floating-icons {
  transition: $transition-medium;
  @include media-max(1200px) {
    position: absolute;
    left: -900px;
  }
}
.c-banner__img {
  width: 100%;
}
.c-banner__button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  @media screen and (min-width: $screen-tablet-portrait) and (max-width: 900px) {
    right: 6px;
    bottom: 6px;
  }
}
.c-banner__title{
  position: relative;

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 115%;
  /* identical to box height, or 37px */

  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;

  /* white */

  color: #FFFFFF;

  text-shadow: 0 0 45px rgba(255, 255, 255, 0.3);
}
.c-banner__value {
  position: relative;
  height: 108px;
  display: table;
  margin: 0 auto;

  color: var(--ellow, #FFE500);
  text-align: center;
  text-shadow: 0px 0px 40.17857360839844px 0px rgba(255, 229, 0, 0.70), 0px 2.6785714626312256px 0px 0px #C0AD00;
  font-family: Rubik, sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 96px */
  letter-spacing: -2.88px;
  text-transform: uppercase;

  @include media-max(1200px){
    font-size: 8em;
  }
}
</style>