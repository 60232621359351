<template>
  <div class="howtowin-container">
    <div style="height: 100%; width: 100%; position: absolute;" @click="closeGuide"></div>
    <div class="howtowin-howtowin">
      <span class="howtowin-text TitleModal"><span>How to win NFTs?</span></span>
      <div class="howtowin-cards">
        <div class="howtowin-frame1">
          <div class="howtowin-top">
            <div class="howtowin-icon">
              <img
                src="../../assets/images/bgi6638-vjkh-200h.png"
                alt=""
                class="howtowin-bg"
              />
              <img
                src="../../assets/svg-icons/svg20pxgemi6638-csid.svg"
                alt=""
                class="howtowin-svg20pxgem"
              />
            </div>
            <div class="howtowin-text02">
              <span class="howtowin-text03 TitleH4">
                <span>collect gems</span>
              </span>
              <span class="howtowin-text05 TextSecondary">
                <span>automatically by just playing for free any game</span>
              </span>
            </div>
          </div>
          <img
            alt="pastedImage"
            src="../../assets/images/pastedimage-k2f-200h.png"
            class="howtowin-pasted-image"
          />
        </div>
        <div class="howtowin-frame2">
          <div class="howtowin-top1">
            <div class="howtowin-icon1">
              <img
                src="../../assets/images/bgi6638-zw4p-200h.png"
                alt="bgI6638"
                class="howtowin-bg1"
              />
              <img
                src="../../assets/svg-icons/svg24pxconverti6638-5ovu.svg"
                alt="SVG24pxconvertI6638"
                class="howtowin-svg24pxconvert"
              />
            </div>
            <div class="howtowin-text07">
              <span class="howtowin-text08 TitleH4">
                <span>convert gems</span>
              </span>
              <span class="howtowin-text10 TextSecondary">
                <span>to Raffle tickets</span>
              </span>
            </div>
          </div>
          <img
            alt="pastedImage"
            src="../../assets/images/pastedimage-athd-200h.png"
            class="howtowin-pasted-image1"
          />
        </div>
        <div class="howtowin-frame3">
          <div class="howtowin-top2">
            <div class="howtowin-icon2">
              <img
                src="../../assets/images/bgi6638-aud5-200h.png"
                alt="bgI6638"
                class="howtowin-bg2"
              />
              <img
                src="../../assets/svg-icons/svg24pxfirei6638-w8p.svg"
                alt="SVG24pxfireI6638"
                class="howtowin-svg24pxfire"
              />
            </div>
            <div class="howtowin-text12">
              <span class="howtowin-text13 TitleH4">
                <span>Burn tickets</span>
              </span>
              <span class="howtowin-text15 TextSecondary">
                <span>to enter daily, weekly or monthly NFTs Raffle</span>
              </span>
            </div>
          </div>
          <div class="howtowin-picture-lottery">
            <img
              src="../../assets/images/pnglottery2i6638-4fs-200w.png"
              alt=""
              class="howtowin-pnglottery2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Howtowin',
  props: {},
  methods:{

    closeGuide(){
      this.guidePopup=false;
      this.$store.commit("setGuideOpen", false)
    },
  }
}
</script>

<style lang="scss" scoped>
.howtowin-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.howtowin-howtowin {
  gap: 30px;
  width: 670px;
  height: 610px;
  display: flex;
  padding: 40px 40px 50px;
  box-shadow: 0px 0px 60px 0px rgba(25, 201, 243, 0.4000000059604645) ;
  align-items: center;
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gradients-blue);
  background-image: linear-gradient(152deg, rgba(0, 198, 251, 1) 0%, rgba(0, 91, 234, 1) 100%);
}
.howtowin-text {
  color: var(--dl-color-default-white);
  width: 450px;
  height: auto;
  text-align: center;
  line-height: 114.99999761581421%;
}
.howtowin-cards {
  gap: 10px;
  display: flex;
  z-index: 1;
  align-items: flex-start;
}
.howtowin-frame1 {
  gap: 5px;
  width: 190px;
  height: 330px;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-background-white10);
}
.howtowin-top {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.howtowin-icon {
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.howtowin-bg {
  top: 0px;
  left: 0px;
  width: 42px;
  height: 42px;
  position: absolute;
}
.howtowin-svg20pxgem {
  top: 10px;
  left: 9px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.howtowin-text02 {
  gap: 5px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.howtowin-text03 {
  color: var(--dl-color-default-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 120.00000476837158%;
}
.howtowin-text05 {
  color: var(--dl-color-default-white);
  height: auto;
  opacity: 0.70;
  align-self: stretch;
  text-align: left;
  line-height: 129.99999523162842%;
}
.howtowin-pasted-image {
  width: 130px;
  height: 130px;
  align-self: center;
}
.howtowin-frame2 {
  gap: 5px;
  height: 330px;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-background-white10);
}
.howtowin-top1 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.howtowin-icon1 {
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.howtowin-bg1 {
  top: 0px;
  left: 0px;
  width: 42px;
  height: 42px;
  position: absolute;
}
.howtowin-svg24pxconvert {
  top: 10px;
  left: 9px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.howtowin-text07 {
  gap: 5px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.howtowin-text08 {
  color: var(--dl-color-default-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 120.00000476837158%;
}
.howtowin-text10 {
  color: var(--dl-color-default-white);
  height: auto;
  opacity: 0.70;
  align-self: stretch;
  text-align: left;
  line-height: 129.99999523162842%;
}
.howtowin-pasted-image1 {
  width: 126px;
  height: 126px;
  align-self: center;
}
.howtowin-frame3 {
  gap: 5px;
  width: 190px;
  height: 330px;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-background-white10);
}
.howtowin-top2 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.howtowin-icon2 {
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.howtowin-bg2 {
  top: 0px;
  left: 0px;
  width: 42px;
  height: 42px;
  position: absolute;
}
.howtowin-svg24pxfire {
  top: 10px;
  left: 9px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.howtowin-text12 {
  gap: 5px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.howtowin-text13 {
  color: var(--dl-color-default-white);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 120.00000476837158%;
}
.howtowin-text15 {
  color: var(--dl-color-default-white);
  height: auto;
  opacity: 0.70;
  align-self: stretch;
  text-align: left;
  line-height: 129.99999523162842%;
}
.howtowin-picture-lottery {
  width: 150px;
  height: 150px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.howtowin-pnglottery2 {
  top: 11px;
  left: 19px;
  width: 113px;
  height: auto;
  position: absolute;
}
.howtowin-iconclose {
  top: 30px;
  left: 616px;
  width: 24px;
  height: 24px;
  opacity: 0.30;
  z-index: 2;
  position: absolute;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.TitleH1 {
  font-size: 148px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 900;
  font-stretch: normal;
}
.TitleH1Mobile {
  font-size: 40px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 900;
  font-stretch: normal;
}
.TitleH2 {
  font-size: 42px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleH2Mobile {
  font-size: 24px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleH3 {
  font-size: 28px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 800;
  font-stretch: normal;
}
.TitleH3Mobile {
  font-size: 20px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleH4 {
  font-size: 20px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleH4Mobile {
  font-size: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleModal {
  font-size: 28px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TitleModalMobile {
  font-size: 20px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-stretch: normal;
}
.TextSubtitle {
  font-size: 32px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 600;
  font-stretch: normal;
}
.TextSubtitleMobile {
  font-size: 20px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 600;
  font-stretch: normal;
}
.TextAccent {
  font-size: 18px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}
.TextBody {
  font-size: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}
.TextBodyMobile {
  font-size: 15px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}
.TextBodyLight {
  font-size: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 300;
  font-stretch: normal;
}
.TextBodyLightMobile {
  font-size: 15px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 300;
  font-stretch: normal;
}
.TextSecondary {
  font-size: 14px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}
.TextTertiary {
  font-size: 12px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 600;
  font-stretch: normal;
}
.ButtonMain {
  font-size: 15px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 600;
  font-stretch: normal;
}
.ButtonSecondary {
  font-size: 14px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
}
</style>
